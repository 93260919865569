import React, {useEffect, useState} from 'react';
import logo from "../data/imgs/actualites/post1.jpg";

function Article(articleInfo){
    console.log(articleInfo);
    let id = articleInfo.ids;
    const [text, setText] = useState("");
    const [date, setDate] = useState("");
    const [image, setImage] = useState("");

        async function fetchTextContent() {
            try {
                const response = await fetch(articleInfo.source);
                let text= await response.text();
                console.log(text);
                let textSplit = text.split("\n");
                setText(textSplit[2]);
                setDate(textSplit[0]);
                console.log(textSplit[1]);
                setImage(textSplit[1]);
            } catch (error) {
                console.error("Error fetching text content:", error);
            }
        }

        fetchTextContent();
        return <>
            <div className={"article"}>
                <div className="container">
                    {id % 2 === 1 ?
                        <>
                            <div className="text">
                                <h3>{date}</h3>
                                <h2>{text}</h2>
                            </div>
                            <div className="image">
                                {image === null || image === '' ? <></> : <img className={"articleImage"}  src={require(`../data/imgs/actualites/${image}`)} alt="Image"/>}
                            </div>
                        </>
                        :
                        <>
                    <div className="image">
                        {image === null || image === '' ? <></> : <img className={"articleImage"}  src={require(`../data/imgs/actualites/${image}`)} alt="Image"/>}
                    </div>
                    <div className="text">
                        <h3>{date}</h3>
                        <h2>{text}</h2>
                    </div></>
                    }
                </div>
            </div>
        </>
}

export default Article;