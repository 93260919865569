
import React from "react";
import Article from "../component/Article";
import post1 from "../data/content/articles/article1.txt";
import post2 from "../data/content/articles/article2.txt";
import post3 from "../data/content/articles/article3.txt";


function Actualites(){

    document.getElementsByTagName("body")[0].style.backgroundColor="#fff1ce";

    let articles = [];
    let id = 0;
    let article = <Article key={id} ids={id} source={post1}/>;
    articles.push(article);

    id += 1;
    article = <Article key={id} ids={id} source={post2}/>;
    articles.push(article);

    id += 1;
    article = <Article key={id} ids={id} source={post2}/>;
    articles.push(article);

    id += 1;
    article = <Article key={id} ids={id} source={post3}/>;
    articles.push(article);

    return <>
        <h1 className="riot-font">Les dernières actualités</h1>
        <div className={"articles"}>

            {articles}


        </div>
    </>;

}

export default Actualites;